import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { Link, useParams } from "react-router-dom";


function Redirect() {
  const { slug } = useParams();
  const [urls, setUrls] = useState([]);

  // Youtube token
  const youtubeToken =
    "QUFFLUhqbU5iNVV0LU4wLU9rZVl5MHdoYkpHYUpEZHRjZ3xBQ3Jtc0tubXl1LW1NX3ZrNi1XZlFROG55TWZtdTdnSm5HYzRnaGtNTmowYlJYUnNXQjRTaTlRTXpPbW5RaG5jN0U5WW9jTV82eElsMGY2cjRqT2R2ZzRaS3dOZlhrQWd2NFVHV0dPNnFBYlVEenp5VnpjTDdQTQ";

  useEffect(() => {
    let query = db.collection("urls").where("slug", "==", slug);
    query.onSnapshot((data) => {
      if (data.empty) {
        alert("URL not found");
      }
      let finalData = data.docs[0].data();
      setUrls(
        [
          finalData.url,
          finalData.url,
          finalData.url,
          finalData.url,
          finalData.url,
          finalData.url,
          finalData.url,
          finalData.url,
          finalData.url,
        ].map((url) => encodeURIComponent(url))
      );
    });
  }, [slug]);

  if (urls.length > 0) {
    let randomIndex = Math.floor(Math.random() * urls.length);
    let redirectUrl = `vnd.youtube://youtube.com/redirect?event=comments&redir_token=${youtubeToken}&q=${urls[randomIndex]}&html_redirect=1`;
    window.location.href = redirectUrl;
  }
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(`${urls}`);
      alert("Link copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className="custom-bg min-h-screen flex items-center justify-center gap-4 flex-col">
      <div>

      </div>
      <div className="flex flex-col justify-center mx-3 gap-5">
        <p className="p-3 bg-black/25 rounded-lg">
          Click the{" "}
          <span className="font-bold text-orange-600">"Copy link"</span> button
          and search or paste the link it in your browser.
        </p>
        <button
          onClick={handleCopy}
          className="btn btn-success vert-move text-white gap-2 px-16 rounded-full flex items-center"
        >
          <i className="fa-solid fa-copy"></i> Copy link
        </button>
      </div>
    </div>
  );
}

export default Redirect;
